<template>
  <div class="orderList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>订单列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 状态 1、申请退款 2、同意退款待用户退货 3、用户已退货待商家确认 4、退货成功(商家确认退货) -->
    <div class="search_box">
      <span>订单状态：</span>
      <el-select
        :clearable="true"
        style="width:200px;"
        v-model.trim="formData.status"
      >
        <el-option :key="-1" label="所有" :value="undefined"></el-option>
        <el-option :key="1" label="申请退款" :value="1"></el-option>
        <el-option :key="2" label="用户已退货待商家确认" :value="2"></el-option>
        <el-option :key="4" label="退货成功" :value="4"></el-option>
      </el-select>
      <span>店铺：</span>
      <el-select
        :clearable="true"
        style="width:200px;"
        v-model.trim="formData.storeId"
      >
        <el-option :key="-1" label="所有" :value="undefined"></el-option>
      </el-select>
      <el-button type="primary" class="btn" @click="getsblist()">查询</el-button>
    </div>

    <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="margin-top:40px; width:98%;">
      <el-table-column label="ID" prop="prod_id" width="50"></el-table-column>
      <el-table-column label="用户头像" width="80">
        <template slot-scope="{row}">
          <el-image style="width:60px; height:60px" :src="row.userInfo.user_headImgUrl" :preview-src-list="[row.userInfo.user_headImgUrl]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="用户昵称" width="100">
        <template slot-scope="{row}">
          {{row.userInfo.user_nickName}}
        </template>
      </el-table-column>
      <el-table-column label="商品封面" prop="prod_home_img" width="100">
        <template slot-scope="{row}">
          <el-image style="width:80px; height:80px" :src="row.prod_home_img" :preview-src-list="[row.prod_home_img]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="商品名称" prop="prod_name" width="300"></el-table-column>
      <el-table-column label="商品规格">
        <template slot-scope="{row}">
          {{row.prod_one_item_name||''}} {{row.prod_two_item_name||''}} {{row.prod_three_item_name||''}}
        </template>
      </el-table-column>
      <el-table-column label="商品价格" prop="prod_buy_price" width="80">
        <!-- <template slot-scope="{row}">
          ￥{{row.prod_buy_price||'0'}} * {{row.prod_buy_count}}
        </template> -->
      </el-table-column>
      <el-table-column label="商品积分" prop="prod_integral" width="80">
        <!-- <template slot-scope="{row}">
          {{row.prod_integral}}积分 * {{row.prod_buy_count}}
        </template> -->
      </el-table-column>
      <el-table-column label="购买数量" prop="prod_buy_count" width="80"></el-table-column>
      <!-- <el-table-column label="购买规格">
        <template slot-scope="{row}">
          {{row.prod_one_item_name||''}}{{row.prod_two_item_name||''}}{{row.prod_three_item_name||''}}
        </template>
      </el-table-column>
      <el-table-column label="申请时间" prop="prod_start_time"></el-table-column>
      <el-table-column label="结束时间" prop="prod_end_time"></el-table-column>
      <el-table-column label="退货快递" prop="prod_end_time">
        <template slot-scope="{row}">
          <p>快递名称:{{row.prod_exit_express_name}}</p>
          <p>快递单号:{{row.prod_exit_express_coding}}</p>
        </template>
      </el-table-column> -->
      <!-- <el-table-column label="购买积分" prop="prod_integral"></el-table-column> -->
      <!-- <el-table-column label="总金额" prop="prod_total_price" width="70"></el-table-column> -->
      <el-table-column label="优惠金额" prop="prod_coupon_price" width="100"></el-table-column>
      <el-table-column label="实付款" prop="prod_balance" width="90"></el-table-column>
      <el-table-column label="退款积分" prop="prod_exit_integral" width="80"></el-table-column>
      <el-table-column label="退款金额" prop="prod_exit_balance" width="80"></el-table-column>
      <el-table-column label="订单状态">
        <template slot-scope="{row}">
          {{
            row.prod_status==1?'待付款' :
            row.prod_status==2?'待发货' :
            row.prod_status==3?'待收货/待提货' :
            row.prod_status==4?'待评价' :
            row.prod_status==5?'申请退款' :
            row.prod_status==6?'待用户退货' :
            row.prod_status==7?'用户已退货' :
            row.prod_status==8?'退货成功' :
            row.prod_status==9?'已完成' :
            row.prod_status==10?'交易取消' :'未知状态'
          }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{row}">
          <div class="operation">
            <el-button size="mini" type="primary" @click="toApply(row, 1)" v-if="row.prod_status==5">
              同意申请
            </el-button>
            <el-button size="mini" type="primary" @click="toApply(row, 2)" v-if="row.prod_status==5">拒绝申请</el-button>
            <el-button size="mini" type="primary" @click="toConfirm(row)" v-if="row.prod_status==7">确认退款</el-button>
            <el-button size="mini" type="primary" @click="showDetail(row)">查看详情</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="formData.currentPage"
      :page-sizes="[5, 10, 15, 20]" :page-size="formData.pageSize" layout="total, sizes, prev, pager, next, jumper"
      :total="formData.total">
    </el-pagination>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="rejectVisible"
      title="发货"
      width="500px"
    >
      <el-form :rules="rejectRule" :model="applyInfo" ref="rejectRuleForm" label-width="80px">
        <el-form-item label="拒绝原因" prop="remark">
          <el-input v-model="applyInfo.remark" :maxlength="1000"  type="textarea" :rows="4" 
          placeholder="请输入拒绝原因" style="width:350px;"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="rejectVisible = false">取 消</el-button>
        <el-button type="primary" @click="toReject()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="sureVisible"
      title="发货"
      width="width"
    >
      <el-form :rules="rejectRule" :model="applyInfo" ref="rejectRuleForm" label-width="80px">
        <el-form-item label="退款金额" prop="remark">
          <el-input-number :step="1" :min="0" v-model="applyInfo.exitBalance" style="width:300px;"></el-input-number>
        </el-form-item>
        <el-form-item label="退款积分" prop="remark">
          <el-input-number :step="1" :min="0" v-model="applyInfo.exitIntegral" style="width:300px;"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="sureVisible = false">取 消</el-button>
        <el-button type="primary" @click="toSure()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { orderService, returnApply, returnApplyCancel, returnConfirm } from '../../../api/selectMall.js'
  export default {
    data() {
      return {
        tableData:[],
        formData:{
          storeId:'', // 商品名称
          status:undefined, // 商品id
          total:0,
          pageSize:10,
          currentPage:1,
        },
        sureVisible:false,
        rejectVisible:false,
        applyInfo:{
          remark:''
        },
        rejectRule:{
          mask:[{ required:true, message:'请输入拒绝原因', trigger:'blur'} ],
        }
      }
    },
    created() {
      this.getsblist()
    },
    methods:{
      async getsblist() {
        const {
          data
        } = await orderService(this.formData);
        this.tableData = data.list;
        this.formData.total = data.pagination.total
        this.formData.pageSize = data.pagination.pageSize;
        this.formData.currentPage = data.pagination.current;
      },
      toApply(row, status) {
        this.applyInfo.prod_id = row.prod_id;
        this.applyInfo.remark = '';
        if (status==1) {
          this.applyInfo.exitIntegral = row.prod_integral
          this.applyInfo.exitBalance = row.prod_buy_price
          this.sureVisible = true
        } if (status==2) {
          this.applyInfo.remark = ''
          this.rejectVisible = true
        }
      },
      async toReject() {
        const { data } = await returnApplyCancel({
          orderProdId:this.applyInfo.prod_id,
          refuseCause:this.applyInfo.remark
        })
        if (data.code==0) {
          this.$message.success(data.msg)
          this.rejectVisible = false
          this.getsblist()
        } else {
          this.$message.error(data.msg)
        }
      },
      async toSure() {
        if (!this.applyInfo.exitIntegral||!this.applyInfo.exitBalance) {
          return this.$message.error('请填写完整数据!')
        }
        const { data } = await returnApply({
          orderProdId:this.applyInfo.prod_id,
          exitIntegral: this.applyInfo.exitIntegral,
          exitBalance: this.applyInfo.exitBalance,
        })
        if (data.code==0) {
          this.$message.success(data.msg)
          this.sureVisible = false
          this.getsblist()
        } else {
          this.$message.error(data.msg)
        }
      },
      toConfirm(row) {
        this.$confirm('确认已收到退货吗?', '提示', {
          confirmBottonText:'确认',
          cancelBottomText:'取消',
          type:'warning',
          center:true
        })
        .then(async () => {
          const { data } = await returnConfirm({
            orderProdId:row.prod_id,
          })
          if (data.code==0) {
            this.$message.success(data.msg)
            this.getsblist()
          } else {
            this.$message.error(data.msg)
          }
        })
      },
      showDetail(row) {
        window.localStorage.setItem('WANLV_sELECTMALL_ORDER_SERVICE_DATA', JSON.stringify(row))
        this.$router.push('/selectMall/order/serviceDetail?id=' + row.prod_id	)
      },
      handleSelectionChange(arr) {
        this.multipleSelection = arr;
      },
      handleSizeChange(num) {
        this.formData.pageSize = num;
        this.getsblist()
      },
      handleCurrentChange(num) {
        this.formData.currentPage = num;
        this.getsblist()
      },
    }
  }
</script>

<style lang="less" scoped="scoped">
  .orderList {


    .el-breadcrumb {
      height:35px;
      border-bottom:1px solid rgb(192, 191, 191);
    }

    .add {
      margin-top:20px;
    }

    .el-table {
      // margin-top:50px;
    }

    .search_box {
      margin-top:24px;
      display:flex;
      align-items:center;
      flex-wrap:wrap;

      span {
        margin-left:20px;
        font-size:15px;
        color:#333333;

        &:first-child {
          margin-left:0;
        }
      }

      .btn {
        margin-left:20px;
      }
    }
    
    .operation {
      /deep/ .el-button+.el-button {
        display:block;
        margin-left:0;
        margin-top:10px;
      }
    }

    .el-pagination {
      margin-top:20px;
      display:flex;
      justify-content:flex-end;
    }
  }
</style>
